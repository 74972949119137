export const ctaStyleMap = {
  "Primary Button": "cta button primary",
  "Secondary Button": "cta button secondary",
  "Primary Text Link": "cta textLink primary",
  "Secondary Text Link": "cta textLink secondary",
};

export const navMap = {
  ContentfulScholarshipAward: 'scholarships',
  ContentfulProject: 'community-projects',
  ContentfulEvent: 'events',
}

export const getUrlPrefix = function getUrlPrefix(cta) {
  if (cta.__typename !== 'ContentfulExternalLink') {
    if (cta.parentPage && cta.parentPage.slug) {
      return `/${cta.parentPage.slug}`;
    } else if (navMap[cta.__typename]) {
      return `/${navMap[cta.__typename]}`;
    }
  }
  return '';
}