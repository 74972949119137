import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer'
import ScrollTrigger from 'react-scroll-trigger';

class Template extends React.Component {
  constructor(props) {
    super(props);

    const documentBody = typeof document !== 'undefined' ? document.getElementsByTagName('body')[0] : null;

    if (documentBody) {
      documentBody.classList.remove('disableScrolling');
    }

    this.state = {
      scrolledToTop: true,
      scrollDirection: null,
      previousProgress: 1
    };

    this.onProgressViewport = this.onProgressViewport.bind(this);
  }

  onProgressViewport({ progress }) {
    const { scrolledToTop, previousProgress } = this.state;

    if (progress < 0.05 && scrolledToTop !== true) {
      this.setState({ scrolledToTop: true, previousProgress: progress })
    } else {
      this.setState({ 
        scrolledToTop: false,
        previousProgress: progress,
        scrollDirection: 
        previousProgress < progress 
          ? 'towardsBottom' 
          : 'towardsTop' 
        })
    }
  }

  render() {
    const { 
      children,
      location
    } = this.props;

    const {
      scrolledToTop,
      scrollDirection
    } = this.state;

    console.log(location);

    return (
      <ScrollTrigger onProgress={this.onProgressViewport} scrollThrottle={50}>
        <Container>
          <Navigation scrolledToTop={scrolledToTop} scrollDirection={scrollDirection} />
          {children}
          <Footer />
        </Container>
      </ScrollTrigger>
    )
  }
}

export default Template

