import React from 'react'
import { StaticQuery, Link } from 'gatsby'
import styles from './footer.module.css'
import { ctaStyleMap } from '../utils/styleMap'


const renderLink = function renderLink(link, topLink) {
  if (link.image && link.image.fluid) {
    return (
      <a
        href={link.url || `${link.parentPage && link.parentPage.slug ? '/' + link.parentPage.slug : ''}/${link.slug}`}
        target={link.openInNewTab ? "_blank" : "_self"}
        title={link.title}
        className={styles.socialLink}
      >
        <img className={styles.logo} alt={link.image.description} src={link.image.fluid.srcWebp} />
      </a>
    );
  } else if (link.url) {
    return (
      <a
        href={link.url || `${link.parentPage && link.parentPage.slug ? '/' + link.parentPage.slug : ''}/${link.slug}`}
        target={link.openInNewTab ? "_blank" : "_self"}
        title={link.title}
        className={`${ctaStyleMap[link.appearance]} gold`}
      >
        <span className={'innerText'}>{link.title}</span>
      </a>
    );
  } else {
    return (
      <Link to={link.parentPage && link.parentPage.slug ? `/${link.parentPage.slug}/${link.slug}` : `/${link.slug}`} className={`cta textLink primary ${styles.footerLink}`}>
        {link.title}
      </Link>
    );
  }
}

const Nav = ({ data }) => {
  const allLinks = 
    data && 
    data.contentfulNavigation && 
    data.contentfulNavigation.contentPages

  return (
    <nav role="navigation">
      <ul className={styles.footerLinks}>
        {allLinks.filter(link => link.image === undefined || link.image === null).map(link => (
          <li key={link.id} className={styles.footerItem}>
            {renderLink(link)}
          </li>
        ))}
      </ul>
      <div className={styles.additionalInfo}>
        <ul className={styles.socialLinks}>
          {allLinks.filter(link => link.image !== undefined && link.image !== null).map(externalLink => (
            <li key={externalLink.id} className={styles.socialLinkItem}>
              {renderLink(externalLink)}
            </li>
          ))}
        </ul>
        <p className={styles.copyright}>{`Copyright ${new Date().getFullYear()} Zonta Club of Toronto`}</p>
      </div>
    </nav>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contentfulNavigation(navigationType: {eq: "Footer"}) {
          id
          navigationType
          contentPages {
            ... on ContentfulContentPage {
              id
              slug
              title
              parentPage {
                slug
              }
            }
            ... on ContentfulExternalLink {
              id
              appearance
              title
              openInNewTab
              url
              parentPage {
                slug
              }
              image: image {
                description
                fluid {
                  srcWebp
                  src
                  srcSetWebp
                  srcSet
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Nav data={data} {...props} />}
  />
)
