export const shapeNavData = function shapeNavData(linkedEntities) {
  let allLinks = [];
  
  if (linkedEntities) {
    const pages = linkedEntities.filter(linkedEntity => Boolean(linkedEntity.slug));
    const navLinks = linkedEntities.filter(linkedEntity => Boolean(linkedEntity.url));
    
    if (pages) {
      pages.forEach(page => {
        const existingLinkIndex = allLinks.findIndex(link => link.slug === page.slug);
        const parentSlug = getParentSlug(page);
        if (parentSlug) {
          let parentLink = allLinks.find(link => link.slug === parentSlug);
          if (parentLink) {
            parentLink.links.push(page);
          } else {
            parentLink = { slug: parentSlug, links: [page] };
            allLinks.push(parentLink);
          }
        } else if (existingLinkIndex !== -1) {
          allLinks[existingLinkIndex].title = page.title;          
        } else {
          allLinks.push({ ...page, links: [] });
        }
      })
    }
    if (navLinks) {
      navLinks.forEach(link => {
        if (link.parentPage) {
          let parentLink = allLinks.find(pageLink => pageLink.slug === link.parentPage.slug);
          if (parentLink) {
            parentLink.links.push(link);
          }
        } else {
          allLinks.push(link);
        }
      })
    }
  }

  return allLinks;
}

const getParentSlug = function getParent(page) {
  if (page.parentPage) {
    return page.parentPage.slug
  } else if (page.__typename === 'ContentfulEvent') {
    return 'events';
  } else if (page.__typename === 'ContentfulProject') {
    return 'service-projects';
  } else if (page.__typename === 'ContentfulScholarshipAward') {
    return 'scholarships';
  } else {
    return false;
  }
}
