import React from 'react'
import { StaticQuery, Link } from 'gatsby'
import styles from './navigation.module.css'
import './base.css'
import { shapeNavData } from '../utils/dataTransforms'

const categories = [
  'events',
  'scholarships',
  'service-projects',
]

const renderButton = function renderButton(link, clickEvent, expandedPanel) {
  return <button aria-controls={link.slug} onClick={clickEvent} className={`${styles.expandableLabel} ${link.slug === expandedPanel ? styles.isExpanded : ''} cta`}>{link.navigationLabel || link.title}</button>
}

const renderLink = function renderLink({ link, parentLink, overrideTitle }) {
  if (!link) {
    return '';
  }
  if (link.image && link.image.fluid) {
    return (
      <a
        href={link.url || `/${parentLink.slug}/${link.slug}`}
        target={link.openInNewTab ? "_blank" : "_self"}
        title={link.navigationLabel || link.title}
        className={`${styles.imageLink} cta text`}
      >
        <img className={styles.logo} alt={link.image.description} src={link.image.fluid.srcWebp} />
      </a>
    );
  } else if (link.url) {
    return (
      <a
        href={link.url || `/${parentLink.slug}/${link.slug}`}
        target={link.openInNewTab ? "_blank" : "_self"}
        title={link.navigationLabel || link.title}
        className={styles.menuLink}
      >
        {overrideTitle || link.navigationLabel || link.title}
      </a>
    );
  } else {
    return (
      <Link to={parentLink ? `/${parentLink.slug}/${link.slug}` : `/${link.slug}`} className={styles.menuLink}>
        {overrideTitle || link.navigationLabel || link.title}
      </Link>
    );
  }
}

const renderLinkList = function renderLinkList(topLink, isExpanded) {
  return (
    <ul className={styles.navigation} id={topLink.slug} aria-hidden={!isExpanded}>
      <li key={topLink.id} className={`${styles.subNavigationItem}`}>
        {renderLink({ 
          link: topLink,
          overrideTitle: categories.includes(topLink.slug) ? `All ${topLink.title}` : topLink.title
        })}
      </li>
      {topLink.links.map(link => (
        <li key={link.id} className={`${styles.subNavigationItem}`}>
          { renderLink({
            link: link,
            parentLink: topLink,
          })}
        </li>
      ))}
    </ul>
  );
}


class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      isOpen: false,
      expandedPanel: null,
    };

    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    e.preventDefault();
    const { expandedPanel } = this.state;
    const panelId = e.currentTarget.getAttribute('aria-controls') || null;
    const updatedExpandedPanel = expandedPanel === panelId ? null : panelId;
    const documentBody = typeof document !== 'undefined' ? document.getElementsByTagName('body')[0] : null;

    if (updatedExpandedPanel === null && documentBody) {
      documentBody.classList.remove('disableScrolling');
    } else if (documentBody) {
      documentBody.classList.add('disableScrolling');
    }

    this.setState({
      expandedPanel: updatedExpandedPanel
    })
  }

  render() {
    const { data, scrolledToTop, scrollDirection } = this.props;
    const { expandedPanel } = this.state;
      console.log(data);
      const allLinks =
        data &&
        data.contentfulNavigation &&
        data.contentfulNavigation.contentPages &&
        shapeNavData(data.contentfulNavigation.contentPages);

      return (
        <>
          <nav role="navigation" className={`${styles.navWrapper} ${expandedPanel ? styles.hasExpandedPanel : ''} ${!scrolledToTop && scrollDirection === 'towardsBottom' ? styles.isHidden : ''}`}>
            <Link to={'/'} className={styles.logoWrapper}>
              {data.contentfulAsset && <img className={styles.logo} alt={data.contentfulAsset.description} src={data.contentfulAsset.fluid.srcWebp} />}
            </Link>
            <a href={'https://www.zonta.org'} className={styles.internationalLink}>
              Zonta International
            </a>
            <ul className={styles.topNavList}>
              {allLinks.map(topLink => {
                const hasLinks = topLink.links && topLink.links.length > 0;
                return (
                  <li key={topLink.id} className={styles.navigationItem}>
                    {hasLinks ? renderButton(topLink, this.togglePanel, expandedPanel) : renderLink(topLink)}
                    {hasLinks && renderLinkList(topLink, expandedPanel === topLink.slug)}
                  </li>
                )
              })}

            </ul>
          </nav>
          <button aria-hidden="true" className={`fullscreenOverlay ${expandedPanel !== null ? 'isExpanded' : ''}`} onClick={this.togglePanel}><span className="visuallyHidden">Close</span></button>
        </>
      );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        contentfulNavigation(navigationType: {eq: "Main Navigation"}) {
          id
          navigationType
          contentPages {
            ... on ContentfulContentPage {
              id
              slug
              title
              navigationLabel
              parentPage {
                slug
              }
            }
            ... on ContentfulScholarshipAward {
              title
              navigationLabel
              slug
              id
            }
            ... on ContentfulProject {
              title
              navigationLabel
              slug
              id
            }
            ... on ContentfulEvent {
              title
              navigationLabel
              slug
              id
            }
            ... on ContentfulExternalLink {
              id
              appearance
              title
              openInNewTab
              url
              parentPage {
                slug
              }
              image: image {
                description
                fluid {
                  srcWebp
                  src
                  srcSetWebp
                  srcSet
                }
              }
            }
          }
        }
        contentfulAsset(contentful_id: {eq: "B3OL23Hj0zVfZbPpZSbZL"}) {
          description
          fluid {
            srcWebp
            src
            srcSetWebp
            srcSet
          }
        }
      }
    `}
    render={data => <Nav data={data} {...props} />}
  />
)
